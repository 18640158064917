import React from "react";

import LayoutSub from "../components/layoutSub";
import SEO from "../components/seo";
import Voice from "../components/voiceNew";


function Voices({ location, pageContext }) {

    const { breadcrumb: { crumbs } } = pageContext;

    const {pathname} = location;
    const pagetitle = "お客様の声";
    const description = "西荻窪・葛西の整体院・ぺんぎん堂のお客様からいただいた声です。腰痛や肩こり、首の痛みなどでお悩みのかたにお越しいただいています。";

    const { state = {} } = location;
    const pathTop = (state) ? state.pathTop : "/";

    return (
        <LayoutSub crumbs={crumbs} pathname={pathname} pathTop={pathTop}>
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
          />

          <section>

              <Voice />
              </section>
    </LayoutSub>
      );
}

export default Voices;