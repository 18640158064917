import React, { useRef } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import ModalComponent from "./modalComponent"

import OutlineZoomIn from "../static/svg/icon-outline-zoom-in.inline.svg";

function VoiceNew() {

  // モーダルの表示状態と切り替える為にState(props)を準備
  // false = 非表示、数値 = 表示しているModalの番目とする
  const [modalIsOpenOne, setIsOpenOne] = React.useState(false);
  const [modalIsOpenTwo, setIsOpenTwo] = React.useState(false);
  const [modalIsOpenThree, setIsOpenThree] = React.useState(false);
  const [modalIsOpenFour, setIsOpenFour] = React.useState(false);

  //どのモーダルを表示するのか操作するために関数を準備
  const handleOpenModalOne = () => {
    setIsOpenOne(true)
  }
  const handleOpenModalTwo = () => {
    setIsOpenTwo(true)
  }
  const handleOpenModalThree = () => {
    setIsOpenThree(true)
  }
  const handleOpenModalFour = () => {
    setIsOpenFour(true)
  }
  const handleOpenModal = (num) => {
    setIsOpen(num)
  }

  // shouldCloseOnEscやshouldCloseOnOverlayCliceを使う場合に設定が必要
  // モーダルを非表示の状態にするため、falseを指定する
  const handleCloseModalOne = () => {
    setIsOpenOne(false)
  }
  const handleCloseModalTwo = () => {
    setIsOpenTwo(false)
  }
  const handleCloseModalThree = () => {
    setIsOpenThree(false)
  }
  const handleCloseModalFour = () => {
    setIsOpenFour(false)
  }

  // モーダルを手前に表示
  const customStyles = {
    overlay: { zIndex: 1000 }
  };


  return (
    <>

      <h1 className="heading-b">お客様の声をご紹介</h1>
      <div className="p-1 bg-yellow-50">
        <p>クライアント様よりいただいた声を紹介させていただきます。<br />
          お声を書いてくださった皆様、ありがとうございました。</p>

        <hr className="topix-border" />

        <div className="overflow-hidden">
          <StaticImage
            src="../images/womanface1.png"
            alt="女性のお客様"
            placeholder="blurred"
            layout="constrained"
            width={50}
            className="float-left mr-2"
          />
          <h2><span className="marker">腰痛・肩こり・首の痛み</span></h2><p className="my-0.5">Jasmine様/38歳</p>
        </div>
        <p><u>施術前と施術後を比べてどうでしたか？</u></p>

        <div className="overflow-hidden">
          <button type='button' className="cursor-zoom-in w-26 float-left mr-2 relative"
            onClick={handleOpenModalOne}>
            <StaticImage
              src="../images/voice001-s.jpg"
              alt="腰痛・肩こり・首の痛みで来院されたお客様の直筆アンケートの画"
              placeholder="blurred"
              layout="constrained"
              width={100}
            />
            <div className="zoom-icon-wrapper text-gray-500">
              <OutlineZoomIn className="w-12 h-12 inline-block mr-2 align-text-bottom" />
            </div>
          </button>

          <ModalComponent
            isOpen={modalIsOpenOne}
            onClose={handleCloseModalOne}
            padding={0}>
            <div className="text-center">
              <StaticImage
                src="../images/voice001.jpg"
                alt="腰痛・肩こり・首の痛みで来院されたお客様の直筆アンケートの画"
                placeholder="blurred"
                layout="constrained"
                className="mx-auto"
              />
            </div>
          </ModalComponent>

          <p>
            ・痛みが緩和されて楽になりました<br />
            ・腰痛がひどくなる頻度がすくなくなった<br />
            ・股関節の痛みがひいた<br />
            ・体のきしみを感じにくくなった</p>

          <p>非常に丁寧でわかりやすく、注意いただいたポイントを気を付けていると生活しやすくなりました！ありがとうございます！！</p>
        </div>

        <hr className="topix-border" />

        <div className="overflow-hidden">
          <StaticImage
            src="../images/womanface2.png"
            alt="女性のお客様"
            placeholder="blurred"
            layout="constrained"
            width={50}
            className="float-left mr-2"
          />
          <h2><span className="marker">慢性的な肩こり・頭痛</span></h2><p className="my-0.5">山畑 由紀江様/50代</p>
        </div>
        <p><u>どの様なお悩みで来院されましたか？</u></p>

        <div className="overflow-hidden">

          <button type='button' className="cursor-zoom-in w-26 float-left mr-2 relative"
            onClick={handleOpenModalTwo}>
            <StaticImage
              src="../images/voice002-s.jpg"
              alt="慢性的な肩こり・頭痛で来院されたお客様の直筆アンケートの画"
              placeholder="blurred"
              layout="constrained"
              width={100}
              className="float-left mr-2"
            />
            <div className="zoom-icon-wrapper text-gray-500">
              <OutlineZoomIn className=" w-12 h-12 inline-block mr-2 text-5xl align-text-bottom" />
            </div>
          </button>

          <ModalComponent
            isOpen={modalIsOpenTwo}
            onClose={handleCloseModalTwo}
            padding={0}>
            <div className="text-center">
              <StaticImage
                src="../images/voice002.jpg"
                alt="慢性的な肩こり・頭痛で来院されたお客様の直筆アンケートの画"
                placeholder="blurred"
                layout="constrained"
                className=""
              />
            </div>
          </ModalComponent>



          <p>慢性的な肩こり・頭痛で悩んでいました。<br />またちょっといつもとちがう作業をすると、腰や足に影響が出やすく、おもだるかったり、痛かったりして日常生活に不便を感じていました。</p>

          <p><u>施術前と施術後を比べてどうでしたか？</u></p>
          <p>施術の後、ベッドから立った時に、”体が軽い”とびっくりしました。</p>
          <p>したほうがいい事、しないほうがいい事を教えてくれて日常生活に役立っています。<br />専門的な事をわかりやすく説明してくれるのがいいです。</p>
        </div>


        <hr className="topix-border" />

        <div className="overflow-hidden">
          <StaticImage
            src="../images/womanface3.png"
            alt="女性のお客様"
            placeholder="blurred"
            layout="constrained"
            width={50}
            className="float-left mr-2"
          />
          <h2><span className="marker">首の痛み、頭痛</span></h2><p className="my-0.5">T.A.様/42歳</p>
        </div>
        <p><u>どの様なお悩みで来院されましたか？</u></p>

        <div className="overflow-hidden">
          <button type='button' className="cursor-zoom-in w-26 float-left mr-2 relative"
            onClick={handleOpenModalThree}>
            <StaticImage
              src="../images/voice003-s.jpg"
              alt="首の痛み、頭痛で来院されたお客様の直筆アンケートの画"
              placeholder="blurred"
              layout="constrained"
              width={100}
              className="float-left mr-2"
            />
            <div className="zoom-icon-wrapper text-gray-500 ">
              <OutlineZoomIn className=" w-12 h-12 inline-block mr-2 text-5xl align-text-bottom" />
            </div>
          </button>
          <ModalComponent
            isOpen={modalIsOpenThree}
            onClose={handleCloseModalThree}
            padding={0}>
            <div className="text-center">
              <StaticImage
                src="../images/voice003.jpg"
                alt="首の痛み、頭痛で来院されたお客様の直筆アンケートの画"
                placeholder="blurred"
                layout="constrained"
                className=""
              />
            </div>
          </ModalComponent>


          <p>首が痛くなり、そのうちに頭痛が出始めて薬を飲むようになり施術をお願いしました。</p>

          <p><u>施術前と施術後を比べてどうでしたか？</u></p>
          <p>首の痛みが緩和して頭痛がなくなりました！<br />体もスッキリしてよく寝れました。</p>

          <p><u>私の対応や説明はどうでしたか？</u></p>
          <p>体の調子が悪くて不安でしたが、丁寧な施術と分かりやすい説明で安心して受ける事ができました。<br />自分の体がどうなっているかを教えて下さり、日頃気を付ける事も教えて下さいました。</p>
        </div>


        <hr className="topix-border" />

        <div className="overflow-hidden">
          <StaticImage
            src="../images/womanface4.png"
            alt="女性のお客様"
            placeholder="blurred"
            layout="constrained"
            width={50}
            className="float-left mr-2"
          />
          <h2><span className="marker">腰痛、右アキレス腱あたりの痛み</span></h2><p className="my-0.5">M.N.様/50代</p>
        </div>
        <p><u>施術前と施術後を比べてどうでしたか？</u></p>

        <div className="overflow-hidden">
          <button type='button' className="cursor-zoom-in w-26 float-left mr-2 relative"
            onClick={handleOpenModalFour}>
            <StaticImage
              src="../images/voice004-s.jpg"
              alt="腰痛、右アキレス腱あたりの痛みで来院されたお客様の直筆アンケートの画"
              placeholder="blurred"
              layout="constrained"
              width={100}
              className="float-left mr-2"
            />
            <div className="zoom-icon-wrapper text-gray-500 ">
              <OutlineZoomIn className=" w-12 h-12 inline-block mr-2 text-5xl align-text-bottom" />
            </div>
          </button>

          <ModalComponent
            isOpen={modalIsOpenFour}
            onClose={handleCloseModalFour}
            padding={0}>
            <div className="text-center">
              <StaticImage
                src="../images/voice004.jpg"
                alt="腰痛、右アキレス腱あたりの痛みで来院されたお客様の直筆アンケートの画"
                placeholder="blurred"
                layout="constrained"
                className=""
              />
            </div>
          </ModalComponent>



          <p>すっきりと痛みがなくなりました。<br />また悩んでいた所だけでなく、肩まわりもとても楽になりました。（夜も熟睡できるようになりました。）</p>

          <p><u>私の対応や説明はどうでしたか？</u></p>
          <p>とても丁寧にみていただき、ひとつひとつ細やかに説明してもらったので安心して施術を受けることができました。<br />落ち着いた それでいて明るいお人柄にも好感がもてます。</p>
        </div>

      </div>
    </>
  )
};

export default VoiceNew